import Vue from 'vue'
import VueRouter from 'vue-router'

import Meeting from "@/views/Meeting.vue";
import NewMeeting from "@/views/NewMeeting.vue";
import PageNotFound from "@/views/PageNotFound.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    { 
      path: "/m/:meetingSlug", 
      component: Meeting 
    },
    {
      path: "/",
      redirect: "/new",
    },
    {
      path: "/new",
      component: NewMeeting,
    },
    {
      path: "/:catchAll(.*)",
      component: PageNotFound,
    },
  ],
});

export default router;
