












































import Vue from 'vue'
export default Vue.extend({
  props: {
    label: {
      type: String,
      required: false,
      default: "",
    },
    value: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    placeholder: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      proxyValue: this.value,
    }
  },
  watch: {
    value: {
      handler(newValue) {
        this.proxyValue = newValue;
        this.$emit('input', newValue);
      },
      deep: true,
    },
  },
})
