




















































import Vue from "vue";
import Modal from "@/components/core/Modal.vue";
import PageHeaderText from "../typography/PageHeaderText.vue";
import UpdateUserForm from "../form/UpdateUserForm.vue";
import FloatingTooltip from "../core/FloatingTooltip.vue";
import UserLoginModal from "./UserLoginModal.vue";
import { cropImageFile } from "@/util";
import { mapState } from "vuex";
export default Vue.extend<any, any, any, any>({
components: {
    Modal,
    PageHeaderText,
    UpdateUserForm,
    FloatingTooltip,
    UserLoginModal,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    position: {
      type: String,
      default: "bottom",
      validator: (x) => ["bottom", "top", "left", "right"].includes(x),
      required: true,
    },
    hiddenActions: {
      default: () => [],
      validator: (x) => x.filter((y: string) => ["editProfile", "openExisting"].includes(y)).length === x.length,
    },
    meetingId: {
      type: String,
      required: false,
      default: "",
    },
    tooltipClasses: {
      type: String,
      default: "w-40",
    },
  },
  data() {
    return {
      showModal: false as false | "EDIT" | "OPEN_EXISTING", 
      userCopy: {
        photoURL: "",
        displayName: "",
        email: "",
        photoFile: null as null | File,
      },
      error: "",
      loading: false,
      loadingEditProfile: false,
      totalActions: [
        {
          name: "editProfile",
          label: "Edit Profile",
          disabled: false,
        },
        {
          name: "openExisting",
          label: "Login",
          disabled: false,
        }
      ]
    };
  },
  mounted(){
    const {photoURL, displayName, email} = this.$props.user || {}
    this.userCopy = { 
      photoURL: photoURL,
      displayName: displayName,
      email: email,
    };
  },
  watch: {
    user: {
      handler(user) {
        this.userCopy = { 
          photoURL: user.photoURL,
          displayName: user.displayName,
          email: user.email,
        };
      },
      deep: true,
    },
    "userCopy.email"(v) {
      if (v) {
        this
          .totalActions
          .find((x: any) => x.name === "openExisting")
          .label = "Logout";
      }
    },
  },
  computed: {
    ...mapState("user", {
      userLoading: "loading",
    }),
    actions() {
      return this.totalActions.filter((x: any) => !this.hiddenActions.includes(x.name));
    },
  },
  methods: {
    async onSelectProfileImage() {
      (this.$refs.fileInput as HTMLInputElement).onchange = () => {
        const [rawFile] = (this.$refs.fileInput as HTMLInputElement).files as FileList;
        if (rawFile) {
          cropImageFile(rawFile, 1, 384).then((croppedFile) => {
            this.userCopy.photoFile = croppedFile;
            this.userCopy.photoURL = URL.createObjectURL(croppedFile);
          });
        } else {
          this.userCopy.photoURL= this.$props.user.photoURL;
          delete this.userCopy.photoFile;
        }
        (this.$refs.fileInput as HTMLInputElement).onchange = null;
      };

      (this.$refs.fileInput as HTMLInputElement).click();
    },
    changeParentIndex() {
      this.showModal = 'OPEN_EXISTING';
      this.onChangeParentIndex(!!this.showModal);

    },
    onChangeParentIndex(e: any) {
      this.$emit("showUserModal", e);
    },
    logout() {
      this.loading = true;
      this.error = "";
      this.$store
        .dispatch("auth/logout")
        .then(() => {
          this.$router.push("/new");
        })
        .catch((error: any) => {
          this.error = error.message || "Something went wrong.";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateUser() {
      this.error = "";
      this.loadingEditProfile = true;
      const { displayName, email, photoFile} = this.userCopy
      this.$store
        .dispatch("user/updateUser", {
          displayName,
          email,
          photoFile,
        })
        .then(() => {
          this.showModal = false;
        })
        .catch((error: any) => {
          this.error = error.message || "Something went wrong.";
        })
        .finally(() => {
          this.loadingEditProfile = false;
        });
    },
    closeUserUpdateModal(){
      this.showModal = false
      const {photoURL, displayName, email} = this.$props.user || {}
      this.userCopy = { 
        photoURL: photoURL,
        displayName: displayName,
        email: email,
      };
    }
  }
})
