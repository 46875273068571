































































import Vue from "vue";
export default Vue.extend<any, any, any, any>({
  watch: {
    open: function (val: boolean) {
      this.$emit("open", val);
      this.loadComponent();      
    },
  },
  mounted() {
    this.loadComponent();
    window.onresize = this.positionTooltip;
    document.addEventListener("scroll", this.positionTooltip, true);
    document.addEventListener("click", (args) => {
      if (
        this.open &&
        args.target &&
        (args.target as any).className &&
        typeof (args.target as any).className === "string" &&
        ![
          "floating-tooltip-button",
          "floating-tooltip",
        ].some((v) => (args.target as any).className.includes(v))
      ) {
        this.close();
      }
    });
    (document.querySelector("body") as HTMLBodyElement).appendChild(
      this.$refs.tooltip
    );
  },
  props: {
    actions: {
      type: Array,
      required: false,
      default: function () {
        return [{
          name: "someAction",
          label: "Some Action",
          disabled: false,
        }];
      },
    },
    tooltipClasses: {
      type: String,
      default: "w-20 sm:w-26 md:w-32",
    },
    position: {
      type: String,
      default: "bottom",
      validator: (x) => ["bottom", "top", "left", "right"].includes(x),
      required: true,
    },
    disableHouver: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hoverOpen: false,
      clickOpen: false,
    };
  },
  computed: {
    open() {
      if (this.clickOpen) return true;
      return this.hoverOpen;
    },
  },
  methods: {
    onActionItemClick(action: string) {
      this.$emit(action);
      this.close();
    },
    changeClickValue(parent: Boolean) {
      if (parent === true) {
        this.clickOpen = false
      } else {
        this.clickOpen = !this.clickOpen;
        this.$emit("changeChevronRotate", this.clickOpen);
      }
    },
    changeHoverValue(value: Boolean) {
      if (!this.disableHouver)
        this.hoverOpen = value;
    },
    positionTooltip() {
      const button = this.$refs.button as HTMLDivElement;
      const tooltip = this.$refs.tooltip as HTMLDivElement;
      const { innerHeight: windowHeight, innerWidth: windowWidth } = window;
      if (button && tooltip) {
        const { 
          top: buttonTop, 
          left: buttonLeft, 
          right: buttonRight, 
          bottom: buttonBottom, 
          height: buttonHeight, 
          width: buttonWidth,
        } = button.getBoundingClientRect();
        const { height, width } = tooltip.getBoundingClientRect();
        let [top, left, bottom, right] = [buttonTop, buttonLeft, buttonBottom, buttonRight];
        if (this.position === "bottom") {
          tooltip.style.top = `${buttonBottom}px`;
          tooltip.style.left = `${buttonLeft}px`;
          [top, left] = [
            buttonTop + buttonHeight, 
            buttonLeft, 
          ];
        } else if (this.position === "top") {
          tooltip.style.top = `${buttonTop - height}px`;
          tooltip.style.left = `${buttonLeft}px`;
          [top, left] = [
            buttonTop - height, 
            buttonLeft, 
          ];
        } else if (this.position === "left") {
          tooltip.style.top = `${buttonTop}px`;
          tooltip.style.left = `${buttonLeft - width}px`;
          [top, left] = [
            buttonTop, 
            buttonLeft - buttonWidth, 
          ];
        } else if (this.position === "right") {
          tooltip.style.top = `${buttonTop}px`;
          tooltip.style.left = `${buttonRight}px`;
          [top, left] = [
            buttonTop, 
            buttonRight,
          ];
        }
        [bottom, right] = [top + height, left + width];
        if (top < 0) {
          tooltip.style.top = `${10}px`;
        }
        if (left < 0) {
          tooltip.style.left = `${10}px`;
        }
        if ((right + 5) > windowWidth) {
          tooltip.style.left = `${windowWidth - width - 5}px`;
        }
        if ((bottom + 5) > windowHeight) {
          tooltip.style.top = `${windowHeight - height - 5}px`;
        }
      }
    },
    close() {
      this.clickOpen = false;
      this.hoverOpen = false;
    },
    loadComponent() {
      this.positionTooltip();
      if (this.$refs.list) {
        for (const listChild of this.$refs.list.children) {
          (listChild as HTMLLIElement).addEventListener("click", (e) => {
            e.stopPropagation();
            this.onActionItemClick((listChild as HTMLLIElement).dataset.actionName);
          });
        }
      }
    }
  },
});
