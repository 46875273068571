
































































import { YacXStore } from "@/store";
import { SEND_TIMEOUT_MS } from "@/store/modules/RecordingModule";
import Vue from "vue";
import { mapState } from "vuex";
import Button from "../core/Button.vue";
export default Vue.extend<any, any, any, any>({
  components: { Button },
  name: "UploadingFile",
  data() {
    return {
      colors: [
        { threshold: 1, color: "text-yellow-300"},
        { threshold: 1 / 2, color: "text-orange-500"},
        { threshold: 1 / 3, color: "text-red-500"},
      ],
    };
  },
  computed: {
    ...mapState({
      timeLeft: (state) => {
        const countdown = (state as YacXStore).recording.sendTimeoutCountdown;
        if (typeof countdown === "number") {
          return countdown;
        } else {
          return 0;
        }
      },
    }),
    timeString() {
      const seconds = this.timeLeft / 1000;
      return seconds.toFixed(0).toString();
    },
    timeFraction() {
      const rawTimeFraction = this.timeLeft / SEND_TIMEOUT_MS;
      return rawTimeFraction - (1 / SEND_TIMEOUT_MS) * (1 - rawTimeFraction);
    },
    currentColorClass() {
      const color = this.colors.find((
        color: { threshold: number, color: string }, 
        arr: { threshold: number, color: string }[]
      ) => {
        return color.threshold <= this.timeFraction && (
          !arr[arr.length - 1] || 
          arr[arr.length - 1].threshold > this.timeFraction
        );
      });
      return color ? color.color : "text-red-500";
    },
    dashArray() {
      const val = (this.timeFraction * 283).toFixed(0);
      return `${val} 283`;
    },
  },
});
