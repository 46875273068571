






















import "@/assets/tailwind.css";
import "@/assets/appearance.css";
import Vue from "vue";
import { mapState } from "vuex";
import { getAuthLoadingMessage } from "./util";
import AnalyticsService from "./services/AnalyticsService";
const analyticsService = new AnalyticsService();

export default Vue.extend({
  name: "App",
  metaInfo: {
    title: 'YacX'
  },
  data() {
    return {
      loadingMessage: getAuthLoadingMessage(),
      interval: null as any,
    };
  },
  computed: {
    ...mapState("user", { userLoading: "load" }),
  },
  mounted() {
    this.interval = setInterval(() => {
      this.loadingMessage = getAuthLoadingMessage();
    }, 5000);
  },
  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
  },
  watch: {
    "$route.query.u": {
      handler(email: string) {
        if (email) {
          const newQuery = { ...(this.$route.query || {}) };
          delete newQuery.u;
          this.$store
            .dispatch("user/setSavedUserEmail", { 
              email: decodeURIComponent(Buffer.from(email, "base64").toString()),
              nonExpiringMagicLink: true,
            })
            .then(() => this.$router.replace({ query: newQuery }))
            .catch(console.error);
        }
      },
      immediate: true,
    },
    "$route.path": {
      handler() {
        analyticsService.trackPage()
      },
      immediate: true,
    },
  }
});
