























































































































































































































































import UploadingFile from "@/components/message/UploadingFile.vue";
import MessageTimeoutPrompt from "@/components/message/MessageTimeoutPrompt.vue";
import MeetingHeader from "@/components/meeting/MeetingHeader.vue";
import MeetingFooter from "@/components/meeting/MeetingFooter.vue";
import LivePlayer from "@/components/video/LivePlayer.vue";
import Messages from "@/components/message/Messages.vue";
import Notes from "@/components/meeting/Notes.vue";
import Modal  from "@/components/core/Modal.vue";
import { debounce } from "@/util";
import { mapState } from "vuex";
import { app } from "@/services/FirebaseService";
import Vue from "vue";
import { ResolvedParticipant } from "@/models/Participant";
import { ResolvedVideo } from "@/models/Video";
import { ResolvedMeeting } from "@/models/Meeting";
import NoMessagePrompt from "@/components/message/NoMessagePrompt.vue";
import Spinner from "@/components/svg/Spinner.vue";
import AnonymousUserNotifyModal from "@/components/user/AnonymousUserNotifyModal.vue";
import NoSleep from "nosleep.js";
import { YacXStore } from "@/store";

export default Vue.extend<any, any, any, any>({
  name: "Meeting",
  components: {
    MeetingHeader,
    MeetingFooter,
    LivePlayer,
    Messages,
    Notes,
    NoMessagePrompt,
    Spinner,
    UploadingFile,
    AnonymousUserNotifyModal,
    MessageTimeoutPrompt,
    Modal,
  },
  data() {
    return {
      loadingMessages: true,
      title: "Yac | Meeting",
      isMobile:
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ),
      meetingSlug: this.$route.params.meetingSlug,
      slugMeetings: [] as ResolvedMeeting[],
      messages: null as ResolvedVideo[] | null,
      participants: [] as ResolvedParticipant[],

      notesCopy: "",
      endNotesCopy: "",
      messageToSendTitle: "",

      sendingMessagePercent: 0,
      sendingMessageError: "",

      notesOpened: false as false | "SUMMARY" | "NOTES",
      lastOpenedNotes: "NOTES" as "SUMMARY" | "NOTES",
      userOpenedNotes: false,

      tabs: [
        { name: "Timeline", status: true, hidden: false },
        { name: "Notes", status: false, hidden: false },
        { name: "Summary", status: false, hidden: true },
      ],

      userPrompt: {
        showModal: false,
      },
      noSleep: null,

      showRequestAccessModal: false,
      accessModalCallback: () => {},
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: "description",
          vmid: 'description',
          content:  "Listen & Reply to this meeting on Yac"
        }
      ]
    }
  },
  watch: {
    "$route.params.meetingSlug": function (n) {
      this.meetingSlug = n;
      this.loadMeetingsFromSlug();
    },
    "recordingReadyForUpload": function(ready){
      if(ready){
        this.processFile();
      }
    },
    "recordingStream": function(stream){
      if(stream){
        this.$store
          .dispatch("transcription/sendStream", { stream })
          .catch((e: any) => {
            console.error(e);
          });
      }
    },
    meetingId() {
      this.loadMeetingSubCollections();
    },
    archived(v) {
      this.tabs.find((t: any) => t.name === "Summary").hidden = !v;
    },
    windowWidth: function (n) {
      if (n > 1024 && !this.notesOpened) {
        this.notesOpened = this.lastOpenedNotes;
      } else if (this.notesOpened && n <= 1024 && !this.userOpenedNotes) {
        this.notesOpened = false;
      }
    },
    "meetingData.notes"(v) {
      this.notesCopy = v || "";
    },
    "meetingData.endNotes"(v) {
      this.endNotesCopy = v || "";
    },
    "meetingData.name"(v) {
      if (v && v.trim().length > 0) {
        this.title = `Yac | ${this.meetingData.name.trim()}`;
      } 
    },
    endNotesCopy(v) {
      this.tabs.find((t: any) => t.name === "Summary").hidden =
        !v && !this.archived;
      if (!this.archived) {
        const updateDebounced = debounce(
          "UPDATE_END_NOTES",
          () => this.updateEndNotes(),
          900
        );
        updateDebounced();
      }
    },
    notesCopy() {
      if (!this.archived) {
        const updateDebounced = debounce(
          "UPDATE_NOTES",
          () => this.updateNotes(),
          900
        );
        updateDebounced();
      }
    },
  },
  mounted() {
    this.loadMeetingsFromSlug();
    if (this.windowWidth > 1024) {
      this.notesOpened = this.lastOpenedNotes;
    }
    this.noSleep = new NoSleep();
  },
  computed: {
    ...mapState({
      user: (state) => (state as YacXStore).user.user,
      sendingMessage: (state) => (state as YacXStore).meeting.uploading,
      recordingStream: (state) => (state as YacXStore).recording.stream,
      recordedData: (state) => (state as YacXStore).recording.recordedData,
      recordingReadyForUpload: (state) => (state as YacXStore).recording.readyForUpload,
      recordingSendTimeoutCountdown: (state) => (state as YacXStore).recording.sendTimeoutCountdown,
      recordScreen: (state) => (state as YacXStore).recording.recordScreen,
      recordingDuration: (state) => (state as YacXStore).recording.duration,
      isRecording: (state) => (state as YacXStore).recording.isRecording,
    }),
    participantUsers() {
      const users = this.participants.filter((u:any)=>u.user !=null).map((p: ResolvedParticipant) => p.user);
      return users;
    },
    meetingData() {
      const [meeting] = this.slugMeetings;
      return meeting || {};
    },
    meetingId() {
      return this.meetingData.id || "";
    },
    isOwner() {
      if (this.meetingData.owner && this.user) {
        const { id } = this.user;
        const { owner } = this.meetingData;
        return id === owner.id;
      }
      return false;
    },
    archived() {
      if (this.meetingData.status) {
        return this.meetingData.status === "ARCHIVED";
      }
      return false;
    },
  },
  methods: {
    getCurrentTab(): any {
      return this.tabs.find((tab: any) => {
        return tab.status;
      }).name;
    },
    toggleNotesOpen() {
      let notesOpened = ("NOTES" as string) || false;
      if (this.windowWidth < 1024) {
        this.userOpenedNotes = !this.notesOpened;
        notesOpened =
          this.notesOpened && this.lastOpenedNotes === notesOpened
            ? false
            : notesOpened;
      }
      this.notesOpened = notesOpened;
      if (notesOpened) this.lastOpenedNotes = notesOpened;
    },
    toggleSummaryOpen() {
      let notesOpened = ("SUMMARY" as string) || false;
      if (this.windowWidth < 1024) {
        this.userOpenedNotes = !this.notesOpened;
        notesOpened =
          this.notesOpened && this.lastOpenedNotes === notesOpened
            ? false
            : notesOpened;
      }
      this.notesOpened = notesOpened;
      if (notesOpened) this.lastOpenedNotes = notesOpened;
    },
    scrollToMessagesBottom() {
      const bottom = this.$refs.messagesBottomContainer;
      bottom.scrollIntoView({
        block: "start",
        behavior: "smooth",
        inline: "start",
      });
    },
    scrollToMessage(e: any) {
      if(e.element || e.element === 0) {
        setTimeout(() => {
          const message = this.$refs.messages.$refs[`message-${e.element}`];
          message[0].$el.scrollIntoView({
            block: e.position,
            behavior: "smooth",
            inline: e.position,
          });
        }, 300);
      }
    },
    // sub-business logic
    loadMeetingsFromSlug() {
      if (!this.meetingSlug) return;
      this.$bind(
        "slugMeetings",
        app
          .firestore()
          .collection("meetings")
          .where("slug", "==", this.meetingSlug)
          .limit(1)
      ).then(() => {
        if (!this.slugMeetings.length) {
          this.$router.push("/new");
        }
      });
    },
    loadMeetingSubCollections() {
      if (!this.meetingId) return;

      document.title =
        `${this.meetingData.name}` ||
        `${this.meetingData.dateCreated.toDate().toLocaleString("default", {
          month: "short",
        })} ${this.meetingData.dateCreated.toDate().getDate()} YacX Meeting`;

      this.$bind(
        "messages",
        app
          .firestore()
          .collection("meetings")
          .doc(this.meetingId)
          .collection("videos")
          .orderBy("sentAt", "asc")
      ).then(() => {
        this.scrollToMessagesBottom();
      }).finally(() => {
        this.loadingMessages = false;
      });

      this.$bind(
        "participants",
        app
          .firestore()
          .collection("meetings")
          .doc(this.meetingId)
          .collection("participants")
      );

      this.$store
        .dispatch("meeting/addMeetingParticipant", {
          id: this.user.id,
          meetingId: this.meetingId,
          automatic: true,
        })
        .catch(console.error);
    },
    updateNotes() {
      if (this.notesCopy === this.meetingData.notes) return;
      if (this.archived) return;
      this.$store.dispatch("meeting/setMeetingNotes", {
        meetingId: this.meetingId,
        notes: this.notesCopy,
      });
    },
    updateEndNotes() {
      if (this.endNotesCopy === this.meetingData.endNotes) return;
      if (this.archived) return;
      this.$store.dispatch("meeting/setMeetingEndNotes", {
        meetingId: this.meetingId,
        endNotes: this.endNotesCopy,
      });
    },

    onMessageToSendTitleChange(title: string) {
      this.messageToSendTitle = title;
    },

    onUserPromptSuccess() {
      // this.sendMessage();
    },

    onUserPromptCancel() {
      // this.$store.dispatch("recording/discardRecording");
      this.$store.commit("meeting/uploading", false);
    },

    discardRecording() {
      this.$store.dispatch("recording/discardRecording")
    },

    retryRecording() {
      const recordScreen = this.recordScreen;
      const meetingTitle = this.meetingData && this.meetingData.name 
        ? this.meetingData.name 
        : "Untitled Meeting";
      this.$store
        .dispatch("recording/discardRecording")
        .then(() => this.$store
          .dispatch("transcription/startTranscription", {
            meetingId: this.meetingId,
            meetingTitle,
          })
        )
        .then(() => this.$store
          .dispatch(
            recordScreen 
              ? "recording/startScreenRecording" 
              : "recording/startAudioRecording",
            recordScreen
              ? {
                  showRequestAccessModal: () => {
                    this.showRequestAccessModal = true;
                  },
                  setAccessModalCallback: (callback: () => void) => {
                    this.accessModalCallback = () => {
                      callback();
                      this.showRequestAccessModal = false;
                    };
                  } 
                }
              : undefined
          )
        )
        .catch((e: any) => {
          console.error("Meeting.vue.retryRecording", e);
        });
    },



    gotoTab(select: string) {
      this.tabs.forEach((tab: any) => {
        tab.status = false;
        if (tab.name === select) tab.status = true;
      });
    },

    onUserPromptSubmit() {
      this.userPrompt.error = "";
      this.userPrompt.loading = true;
      this.$store
        .dispatch("user/updateUser", {
          email: this.userPrompt.email,
        })
        .then(() => {
          this.userPrompt.showModal = false;
          this.userPrompt.loading = false;
          return this.processFile();
        })
        .catch((err: any) => {
          this.userPrompt.error = err.message || "An error ocurred.";
          this.userPrompt.loading = false;
        });
    },

    onUserPromptClose() {
      this.userPrompt.showModal = false;
    },
    async processFile() {
      this.sendingMessageError = "";
      this.scrollToMessagesBottom();
      this.$store.commit("meeting/uploading", true);
      await this.sendMessage();
    },
    onUploadProgress(v: number) {
      this.sendingMessagePercent = v;
    },
    async sendMessage() {
      this.sendingMessageError = "";
      this.scrollToMessagesBottom();
      this.$store.commit("meeting/uploading", true);
      
      try {
        const file = await this.$store.getters['recording/getEncodedFile']();

        await this.$store.dispatch("meeting/addVideo", {
          meetingId: this.meetingId,
          file: file,
          byUserId: this.user.id,
          video: this.recordScreen,
          onUploadProgress: this.onUploadProgress,
          duration: this.recordingDuration,
          title: this.messageToSendTitle,
          store: this.$store,
          kickoff: !this.messages.length,
          type: this.recordScreen
            ? "SCREEN_SHARE"
            : "VOICE",
        });
        await this.$store.dispatch("transcript/")
      } catch (err: any) {
        this.sendingMessageError = err.message || "Could not send message.";
      } finally {
        this.$store.dispatch("recording/reset");
        this.messageToSendTitle = "";
        this.scrollToMessagesBottom();
        if (!(this.user && this.user.email)) {
          this.userPrompt.showModal = true;
        }
      }
    },
    enable() {
      if (this.noSleep) this.noSleep.disable(); // Just to be sure if you forgot to disable.
      this.noSleep = new NoSleep();
      this.noSleep.enable();
    },
    disable() {
      this.noSleep.disable();
    },
  },
});
