var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[_c('div',{staticClass:"overflow-y-auto absolute top-0 bottom-0 left-0 right-0"},[_vm._l((_vm.dialogues),function(ref,i){
var startSeconds = ref.startSeconds;
var transcript = ref.transcript;
return _c('div',{key:("dialogue_" + _vm.messageId + "_" + (Math.floor(startSeconds)) + "_" + (_vm.toBase64(transcript))),class:("flex gap-1 rounded-md py-1 cursor-pointer hover:bg-gray-200 " + ((
          (!_vm.liveMode && _vm.openDialogueTime === Math.floor(startSeconds)) || 
          (_vm.liveMode && i === (_vm.dialogues.length - 1))
        ) && !_vm.hideHighlight
          ? 'bg-yellow-400 bg-opacity-20' 
          : 'opacity-60')),attrs:{"id":("dialogue_" + _vm.messageId + "_" + (Math.floor(startSeconds)))},on:{"click":function($event){return _vm.onTranscriptDialogueClick(startSeconds)}}},[_c('div',{staticClass:"opacity-60 min-w-[3rem] text-right font-medium",attrs:{"id":("time_" + _vm.messageId + "_" + (Math.floor(startSeconds)))}},[_vm._v(" "+_vm._s(_vm._f("formatSeconds")(startSeconds))+" ")]),_c('div',[_vm._v(" "+_vm._s(transcript)+" ")])])}),_c('div',{ref:"bottom"})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }