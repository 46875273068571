import { Store } from "vuex";
import { YacXStore } from "..";

const micPermission = "microphone" as PermissionName;

export function MicrophonePermission(){
    return (store:Store<YacXStore>) =>{
        if(navigator.permissions){
            navigator.permissions.query({name: micPermission}).then((status)=>{
                store.dispatch("recording/microphonePermission", status.state);

                status.onchange = (ev:any)=>{
                    if(ev.type === "change"){
                        if(ev.target && ev.target.state){
                            store.dispatch("recording/microphonePermission", ev.target.state);
                        }
                        console.log('STATUS CHANGE', ev);
                    }
                };
            });
        }else{
            console.log('SAFARI?');
            store.dispatch("recording/safariPermission", true);
            // navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((stream)=>{
            //     stream.getTracks().forEach(track=>track.stop());
                
            // }).catch((err)=>{
            //     store.dispatch("recording/safariPermission", false);
            //     console.log('safari err');
            //     console.log(err);
            // });
        }
    }   
}