<template>
  <div
    class="
      flex
      gap-2
      w-full
      p-1
      h-auto
      relative
      border border-gray-200
      rounded
      overflow-auto
    "
  >
    <div
      class="flex bg-gray-100 w-auto rounded p-2 justify-between relative"
      v-for="(item, index) in list"
      :key="index"
    >
      <div class="tags-text opacity-75 font-medium text-xs md:text-base">
        {{ item }}
      </div>

      <svg
        @click="remove(index)"
        class="
          w-3
          h-3
          my-auto
          ml-2
          hover:opacity-75
          cursor-pointer
          text-gray-400
        "
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </div>
    <div class="w-full flex flex-grow flex-shrink-0">
      <input
        class="w-full outline-none flex flex-grow p-2 text-xs md:text-base border-none"
        type="text"
        v-model="text"
        v-on:keyup.13="submit('enter')"
        v-on:keyup.188="submit"
        v-on:keyup.32="submit"
        v-on:focusout="submit"
        :placeholder="placeholder"
        @keyup="$emit('keyup', $event)"
      />
    </div>
  </div>
</template>
<script>
import { validateEmail } from '@/util';

export default {
  name: "TagInput",
  props: {
    list: {
      type: Array,
    },
    placeholder: {
      type: String,
    },
    emailValidation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      text: "",
    };
  },
  watch: {
    text: function (n) {
      this.$emit("search", n);
    },
  },
  methods: {
    remove(index) {
      this.list.splice(index, 1);
    },
    submit(code) {
      this.text = this.text.toLowerCase().trim().replace(",", "");
      const validEmail = validateEmail(this.text);
      const isNotExist = this.list.every((item) => {
        return item != this.text;
      });
      if (isNotExist && (validEmail || !this.emailValidation)) {
        this.list.push(this.text.replace(",", ""));
        this.text = "";
        this.$emit("change", this.list);
      } else {
        this.text = "";
      }
      if (code == "enter") this.$emit("submit");
    },
  },
};
</script>