































import AnalyticsService from '@/services/AnalyticsService';
import Vue from 'vue';
const analyticsService = new AnalyticsService();
export default Vue.extend({
  data() {
    return {
      openDialogueTime: 0,
    }
  },
  props: {
    messageId: {
      type: String,
      required: true,
      default: "",
    },
    transcript: {
      type: String,
      required: true,
      default: "",
    },
    currentTime: {
      type: Number,
      default: 0,
    },
    dialogues: [],
    words: [],
    hideHighlight: {
      type: Boolean,
      default: false,
    },
    liveMode: {
      type: Boolean,
      default: false,
    },
  },
  filters: {
    formatSeconds(v: number) {
      const s = Number(v);
      const seconds = Math.floor(s % 60).toString().padStart(2, "0");
      const minutes = Math.floor(s / 60).toString().padStart(2, "0")
      return `${minutes}:${seconds}`
    }
  },
  watch: {
    currentTime(v: number) {
      const el = document.getElementById(`dialogue_${this.messageId}_${v}`);
      if (el != null && el.parentNode) {
        this.openDialogueTime = v;
        const parent: HTMLElement = el.parentNode as HTMLElement;
        parent.scrollTop = el.offsetTop;
      }
    },
    dialogues: {
      handler() {
        const bottom = this.$refs.bottom as HTMLDivElement;
        if (this.liveMode && bottom) {
          bottom.scrollIntoView({
            block: "start",
            behavior: "smooth",
            inline: "start",
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    onTranscriptDialogueClick(startSeconds: number) {
      this.$emit('setCurrentTime', { 
        messageId: this.messageId, 
        time: Math.floor(startSeconds) 
      });
      analyticsService.recordAnalyticsEvent("TRANSCRIPT_TIMESTAMP_CLICK", {});
    },
    toBase64(str: string) {
      return Buffer.from(str).toString("base64")
    }
  },
});
