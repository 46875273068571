<template>
  <div class="w-full flex flex-col mb-4 select-none">
    <div class="font-serif text-3xl font-bold"><slot name="title" /></div>
    <div class="mt-4 text-lg font-sans"><slot name="subtitle" /></div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>