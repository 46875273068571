<template>
  <section
    :class="`fixed flex justify-between w-full h-auto p-3 bottom-0 bg-white ${
      showFinalizeModal || userModal ? 'z-40' : 'z-20'
    }`"
  >
    <div 
      v-if="floatingMessage" 
      class=" absolute top-[-3rem] left-0 right-0 flex justify-center h-0"
    >
        <div class="h-10 bg-black px-3 rounded-lg flex items-center text-white">
        {{floatingMessage}}
      </div>
    </div>
    <UserOptionsDropdown
      position="top"
      :show="showFinalizeModal"
      @showUserModal="showUserModal"
      :user="user"
      tooltipClasses="min-w-40 w-auto"
      :meetingId="meetingId"
      :hiddenActions="user.email ? [] : ['editProfile']"
    >
      <button
        v-if="notRecording"
        class="flex justify-center center-content flex-shrink md:flex-shrink-0"
        id="userOptionsFooterButton"
      >
        <div class="w-10 h-10 overflow-hidden flex my-auto md:mr-3">
          <img
            :src="user.photoURL"
            class="rounded-full object-cover w-full h-full"
          />
        </div>
        <div
          class="flex-col font-bold justify-start text-left hidden xs:block pl-1 sm:pl-[0.4rem]"
        >
          <p class="leading-tight">
            {{
              `${
                user.email
                  ? user.realName || user.email
                  : user.displayName || ""
              }`
            }}
          </p>
          <p v-if="user.plan === 'PRO'" class="bg-gradient-pro text-sm">
            Pro Since 2022
          </p>
        </div>
      </button>
    </UserOptionsDropdown>
    <article v-if="!archived" class="flex justify-start space-x-3">
      <div v-if="isRecording" class="center-content">
        <div class="center-content">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="none" fill-rule="evenodd">
              <circle r="2.5" transform="translate(13 13)" fill="#FF1E1E" />
            </g>
          </svg>
          <p>
            Recording:
            {{ $store.state.recording.recordScreen ? "Screen" : "Audio" }}
          </p>
        </div>
        <portal-target
          class="small-portal"
          style="padding-left: 12px"
          name="waveform"
        />
      </div>
      <Button
        @click.native="startRecording"
        v-if="!isRecording"
        :loading="uploading || sendMessageLoading"
        :disabled="clickLoading"
        :class="`
          mr-2
          select-none
          ${isRecording && !isPreviewing ? 'bg-red-500' : 'bg-yellow-300'} 
          ${isRecording && !isPreviewing ? 'text-white' : 'text-black'}
          ${clickLoading ? 'cursor-wait' : ''}
          transition-colors
          focus:outline-yellow-300
        `"
      >
        <div class="flex justify-center align-middle">
          <FloatingTooltip
            position="top"
            ref="devicesOption"
            tooltipClasses="w-96 large-radius box-shadow-dark"
            :disableHouver="true"
            :isRecording="isRecording"
            @changeChevronRotate="changeChevronRotate"
          >
            <template v-slot:button>
              <div class="flex">
                <MicIcon
                  style="
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                    margin-left: -4px;
                  "
                  :fill="`${isRecording && !isPreviewing ? '#fff' : '#000'}`"
                />
                <DownChevron
                  :class="`rotate mr-2`"
                  style="width: 9px; height: 16px"
                />
              </div>
            </template>
            <div style="font-size: 14px" class="pt-4">
              <div class="px-4">
                <h6 class="font-bold">Microphone Input</h6>
              </div>
              <ul
                style="border: 1px solid transparent"
                class="py-1"
                aria-labelledby="dropdownButton"
              >
                <li
                  v-for="(device, i) in devices"
                  :key="`device-${i}`"
                  @click="selectDefaultDevice(device.deviceId)"
                  class="flex items-center participant-box participant-box-anonymous transition-all duration-100"
                >
                  <div
                    class="w-full py-2 px-3 h-full flex flex-row justify-between align-middle"
                    style="align-items: center; cursor: pointer"
                  >
                    <p>{{ device.label }}</p>
                    <span class="w-10 flex justify-end">
                      <svg
                        v-if="device.deviceId === defaultDeviceId"
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          transform="translate(2 2)"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <rect
                            fill="#F3C93C"
                            x="-1.773"
                            y="-1.773"
                            width="16.545"
                            height="16.545"
                            rx="6"
                          />
                          <path
                            d="M10.576 3.177c-1.942 1.93-3.712 3.84-5.627 5.755L2.387 6.901a.379.379 0 0 0-.532.058.373.373 0 0 0 .06.527l2.834 2.249a.38.38 0 0 0 .502-.03c2.023-2.01 3.85-4 5.857-5.995a.372.372 0 0 0 .082-.436.378.378 0 0 0-.614-.097z"
                            fill="#141414"
                          />
                        </g>
                      </svg>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </FloatingTooltip>
        </div>
        <p>Record Message</p>
      </Button>
      <Button
        v-if="
          !uploading &&
          !isMobile &&
          !isRecording &&
          !isPreviewing &&
          !sendMessageLoading
        "
        @click.native="onSharedScreenButtonClick"
        :disabled="clickLoading"
        :class="` 
          relative flex-shrink-0 
          my-auto 
          focus:outline-yellow-300
          ${clickLoading ? 'cursor-wait' : ''}
          bg-transparent transition-colors`"
        style="
          border-radius: 20px;
          border: solid 1px rgba(20, 20, 20, 0.1);
          background-color: #fff;
        "
      >
        <ShareScreenIcon
          class="sm:mr-2 w-[22px] h-[22px] sm:w-[18px] sm:h-[18px] opacity-50"
        />
        <p class="hidden sm:block">Share Screen</p>
      </Button>
      <Button
        v-if="isRecording"
        class="select-none py-0 px-0 relative flex-shrink-0 my-auto text-black border-2"
      >
        <div @click="upload" class="center-content right-divider p-2 pl-4">
          <div class="h-3 w-3 bg-red-500 rounded-sm mr-2" />
          {{ $store.state.recording.duration | formatAsTimecode }}
        </div>
        <div class="pr-4 p-2 z-10" @click="onDiscardCurrentMessage">
          <XIcon class="w-4 h-4" />
        </div>
      </Button>
    </article>
    <article
      v-if="archived && notRecording && !isMobile"
      class="flex justify-center items-center space-x-3"
    >
      <p>This meeting has been archived</p>
    </article>
    <article
      v-if="notRecording"
      class="flex justify-end flex-shrink md:flex-shrink-0 pl-2 md:pl-20"
    >
      <Button
        @click.native="onFinalizeModalConfirm"
        :class="`
          ${archived ? 'bg-yellow-300 text-black' : 'bg-red-500 text-white'}
          relative
          flex-shrink-0
          my-auto
          focus:outline-violet-200
        `"
      >
        {{ archived ? "Create New Meeting" : "Archive" }}
      </Button>
    </article>
    <Modal
      :show="showFinalizeModal"
      slotMode
      :closeOnConfirm="false"
      :confirmText="archived ? 'Create New Meeting' : 'End & Archive Meeting'"
      :confirmType="archived ? 'success' : 'danger'"
      @confirm="
        archived
          ? onFinalizeModalConfirm({ modal: true })
          : (archiveConfirm = true)
      "
      :hideConfirm="archiveConfirm"
      :confirmLoading="endNotesObj.loading"
      @close="showFinalizeModal = false"
      :class="`${archived ? 'summayModal' : ''}`"
    >
      <div v-if="archiveConfirm">
        <div>
          <p class="text-xl font-bold pb-4">Archive this meeting?</p>
          <p class="text-sm pb-2">
            When you archive a meeting, it's archived for everyone. That
            means...
          </p>
          <div style="padding-left: 23px" class="pb-3">
            <ul class="text-sm" style="list-style: disc">
              <li>No one will be able to send messages to the meeting</li>
              <li>Notes and summary will be disabled</li>
              <li>
                The shared notes and summary will be sent in an email to all
                participants
              </li>
            </ul>
          </div>
          <p class="text-sm pb-3">
            You'll still be able to navigate the meeting, listen to messages,
            and view notes/summary. You will not be able to unarchive the
            meeting in the future.
          </p>
        </div>
      </div>
      <div v-else>
        <div v-if="!archived" class="pb-5">
          <p class="text-xl font-bold pb-2">Meeting Summary</p>
          <span class="text-sm" style="color: #7b7b7b">
            All participants will be sent this summary once you end and archive
            this meeting.
          </span>
        </div>
        <div v-else class="pb-5">
          <p class="text-xl font-bold pb-2">This meeting has been archived</p>
          <span class="text-sm" style="color: #7b7b7b">
            This meeting has been archived by a participant.
          </span>
        </div>
        <Notes
          :meetingId="meetingId"
          class="border-2 border-gray-200 rounded-lg h-full w-full focus-within:border-yellow-300 min-h-[40vh] max-h-[60vh]"
          :disabled="archived"
          v-if="!archived"
          v-model="endNotesObj.text"
          placeholder="endNotes"
        />
        <div v-else>
          <hr />
          <p class="text-xl font-bold py-5">{{ `${title} Meeting Summary` }}</p>
          <Notes
            :meetingId="meetingId"
            class="rounded-lg h-full w-full focus-within:border-yellow-300 min-h-[40vh] max-h-[60vh]"
            :disabled="archived"
            v-model="endNotesObj.text"
            placeholder="endNotes"
          />
        </div>
      </div>
      <p class="text-red-600 pb-2">{{ endNotesObj.error }}</p>
      <template v-slot:extraFooter v-if="archived || archiveConfirm">
        <div class="flex flex-wrap gap-2 justify-start mb-2">
          <Button
            v-if="!archiveConfirm"
            :loading="docxExportLoading"
            class="inline-flex w-auto cursor-pointer border-2 px-4 py-2 bg-white text-sm leading-6 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-yellow-300 wfull-m focus:shadow-outline-yellow"
            @click="docxExport"
          >
            <svg style="height: 20px; width: 20px; padding-right: 5px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96" fill="#FFF" stroke-miterlimit="10" stroke-width="2">
              <path stroke="#979593" d="M67.1716,7H27c-1.1046,0-2,0.8954-2,2v78 c0,1.1046,0.8954,2,2,2h58c1.1046,0,2-0.8954,2-2V26.8284c0-0.5304-0.2107-1.0391-0.5858-1.4142L68.5858,7.5858 C68.2107,7.2107,67.702,7,67.1716,7z"/>
              <path fill="none" stroke="#979593" d="M67,7v18c0,1.1046,0.8954,2,2,2h18"/>
              <path fill="#C8C6C4" d="M79 61H48v-2h31c.5523 0 1 .4477 1 1l0 0C80 60.5523 79.5523 61 79 61zM79 55H48v-2h31c.5523 0 1 .4477 1 1l0 0C80 54.5523 79.5523 55 79 55zM79 49H48v-2h31c.5523 0 1 .4477 1 1l0 0C80 48.5523 79.5523 49 79 49zM79 43H48v-2h31c.5523 0 1 .4477 1 1l0 0C80 42.5523 79.5523 43 79 43zM79 67H48v-2h31c.5523 0 1 .4477 1 1l0 0C80 66.5523 79.5523 67 79 67z"/>
              <path fill="#185ABD" d="M12,74h32c2.2091,0,4-1.7909,4-4V38c0-2.2091-1.7909-4-4-4H12c-2.2091,0-4,1.7909-4,4v32 C8,72.2091,9.7909,74,12,74z"/>
              <path d="M21.6245,60.6455c0.0661,0.522,0.109,0.9769,0.1296,1.3657h0.0762 c0.0306-0.3685,0.0889-0.8129,0.1751-1.3349c0.0862-0.5211,0.1703-0.961,0.2517-1.319L25.7911,44h4.5702l3.6562,15.1272 c0.183,0.7468,0.3353,1.6973,0.457,2.8532h0.0608c0.0508-0.7979,0.1777-1.7184,0.3809-2.7615L37.8413,44H42l-5.1183,22h-4.86 l-3.4885-14.5744c-0.1016-0.4197-0.2158-0.9663-0.3428-1.6417c-0.127-0.6745-0.2057-1.1656-0.236-1.4724h-0.0608 c-0.0407,0.358-0.1195,0.8896-0.2364,1.595c-0.1169,0.7062-0.211,1.2273-0.2819,1.565L24.1,66h-4.9357L14,44h4.2349 l3.1843,15.3882C21.4901,59.7047,21.5584,60.1244,21.6245,60.6455z"/>
            </svg>
            Export to Word
          </Button>
          <Button
            v-if="!archiveConfirm"
            class="inline-flex w-auto cursor-pointer border-2 px-4 py-2 bg-white text-sm leading-6 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-yellow-300 wfull-m focus:shadow-outline-yellow"
            @click="notionExport"
          >
            <svg
              style="height: 20px; width: 20px; padding-right: 5px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="12 0.18999999999999906 487.619 510.941"
            >
              <path
                d="M96.085 91.118c15.81 12.845 21.741 11.865 51.43 9.884l279.888-16.806c5.936 0 1-5.922-.98-6.906L379.94 43.686c-8.907-6.915-20.773-14.834-43.516-12.853L65.408 50.6c-9.884.98-11.858 5.922-7.922 9.883zm16.804 65.228v294.491c0 15.827 7.909 21.748 25.71 20.769l307.597-17.799c17.81-.979 19.794-11.865 19.794-24.722V136.57c0-12.836-4.938-19.758-15.84-18.77l-321.442 18.77c-11.863.997-15.82 6.931-15.82 19.776zm303.659 15.797c1.972 8.903 0 17.798-8.92 18.799l-14.82 2.953v217.412c-12.868 6.916-24.734 10.87-34.622 10.87-15.831 0-19.796-4.945-31.654-19.76l-96.944-152.19v147.248l30.677 6.922s0 17.78-24.75 17.78l-68.23 3.958c-1.982-3.958 0-13.832 6.921-15.81l17.805-4.935V210.7l-24.721-1.981c-1.983-8.903 2.955-21.74 16.812-22.736l73.195-4.934 100.889 154.171V198.836l-25.723-2.952c-1.974-10.884 5.927-18.787 15.819-19.767zM42.653 23.919l281.9-20.76c34.618-2.969 43.525-.98 65.283 14.825l89.986 63.247c14.848 10.876 19.797 13.837 19.797 25.693v346.883c0 21.74-7.92 34.597-35.608 36.564L136.64 510.14c-20.785.991-30.677-1.971-41.562-15.815l-66.267-85.978C16.938 392.52 12 380.68 12 366.828V58.495c0-17.778 7.922-32.608 30.653-34.576z"
                fill-rule="evenodd"
              />
            </svg>
            Export to Notion
          </Button>
          <Button
            v-if="!archiveConfirm"
            class="inline-flex w-auto cursor-pointer border-2 px-4 py-2 bg-white text-sm leading-6 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-yellow-300 focus:shadow-outline-yellow wfull-m"
            @click="googleDocsExport"
          >
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 64 88"
              style="height: 20px; padding-right: 5px"
              xml:space="preserve"
            >
              <g id="Layer_5">
                <path
                  class="st0"
                  style="fill: #3086f6"
                  d="M58,88H6c-3.3,0-6-2.7-6-6V6c0-3.3,2.7-6,6-6h36l22,22v60C64,85.3,61.3,88,58,88z"
                />
                <path class="st1" style="fill: #0c67d6" d="M42,0l22,22H42V0z" />
                <path
                  class="st2"
                  style="fill: #fdffff"
                  d="M50,39H14v-5h36V39z M50,46H14v5h36V46z M40,58H14v5h26V58z"
                />
              </g>
            </svg>
            Export to Google Docs
          </Button>
        </div>
        <div v-if="!isMobile" style="width: 4em"></div>
        <Button
          v-if="archiveConfirm"
          @click="
            showFinalizeModal = false;
            archiveConfirm = false;
          "
          type="button"
          :class="`
              inline-flex
              justify-center
              w-auto
              px-4
              py-2
              leading-6
              border-2
              bg-transparent`"
          style="
            border-radius: 20px;
            border: solid 1px rgba(20, 20, 20, 0.1);
            background-color: #fff;
          "
        >
          Cancel
        </Button>
        <Button
          v-if="archiveConfirm"
          @click="
            archiveConfirm = false;
            onFinalizeModalConfirm({ modal: true });
          "
          type="button"
          :class="`
              inline-flex
              justify-center
              w-auto
              px-4
              py-2
              leading-6
              border-2
             text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red border-red-600`"
        >
          Archive Meeting
        </Button>
      </template>
    </Modal>
    <Modal
      :show="showGoogleLogin"
      :hideConfirm="true"
      confirmType="success"
      slotMode
      @close="showGoogleLogin = false"
    >
      <p class="text-md text-gray-900">
        To continue the export to Google Docs, please sign in below.
      </p>
      <p>
        <Button @click="openGoogleSignin">
          <img src="/btn_google_signin_dark_pressed_web.png" />
        </Button>
      </p>
    </Modal>
    <Modal
      :show="recordSettings.getStreamError"
      :hideConfirm="true"
      confirmType="success"
      slotMode
      @close="recordSettings.getStreamError = false"
    >
      <div
        v-if="recordSettings.error === 'COULD_NOT_GET_AUDIO_STREAM'"
        class="flex items-center flex-col"
      >
        <img
          src="/images/no-microphone.png"
          srcset="
            /images/no-microphone@2x.png 2x,
            /images/no-microphone@3x.png 3x
          "
          class="mt-6"
        />
        <h2 class="error-title mt-6">We can’t hear you.</h2>
        <div class="font-bold text-gray-900 my-6 mx-10">
          <ol type="1" class="list-decimal">
            <li>Make sure your browser has access to your mic (please review your system preferences).</li>
            <li>Force quit the browser.</li>
            <li>Open this page again.</li>
            <li>Make sure you are using HTTPS.</li>
            <li>If all fails send a screen recording or description of your error to felipe@yac.com or anyone at Yac.</li>
          </ol>
        </div>
        <button
          class="bg-black text-white w-full focus:outline-violet-200 relative text-sm my-auto font-medium rounded-full py-2 px-4 cursor-pointer"
          @click="recordSettings.getStreamError = false"
        >
          Got it
        </button>
      </div>
      <div
        v-if="recordSettings.error === 'COULD_NOT_GET_VIDEO_STREAM'"
        class="flex items-center flex-col"
      >
        <img
          src="/images/no-screen-recording.png"
          srcset="
            /images/no-screen-recording@2x.png 2x,
            /images/no-screen-recording@3x.png 3x
          "
          class="mt-6"
        />
        <h2 class="error-title mt-6">We can’t show your screen.</h2>
        <div class="font-bold text-gray-900 my-6 mx-10">
          <ol type="1" class="list-decimal">
            <li>Make sure your browser has screen recording permissions (please review your system preferences).</li>
            <li>Force quit the browser.</li>
            <li>Open this page again.</li>
            <li>Make sure you are using HTTPS.</li>
            <li>If all fails send a screen recording or description of your error to felipe@yac.com or anyone at Yac.</li>
          </ol>
        </div>
        <button
          class="bg-black text-white w-full focus:outline-violet-200 relative text-sm my-auto font-medium rounded-full py-2 px-4 cursor-pointer"
          @click="recordSettings.getStreamError = false"
        >
          Got it
        </button>
      </div>
    </Modal>
    <Modal
      :show="showRequestAccessModal"
      :hideConfirm="true"
      confirmType="success"
      slotMode
      @close="showRequestAccessModal = false"
    >
      <div
        class="flex items-center flex-col"
      >
        <h2 class="error-title mt-6 mb-6">Please allow screen recording and mic access.</h2>
        <button
          class="bg-black text-white w-full focus:outline-violet-200 relative text-sm my-auto font-medium rounded-full py-2 px-4 cursor-pointer"
          @click="accessModalCallback"
        >
          Continue
        </button>
      </div>
    </Modal>
  </section>
</template>

<script>
import Button from "@/components/core/Button.vue";
import MicIcon from "@/components/svg/MicIcon.vue";
import DownChevron from "@/components/svg/DownChevron.vue";
import FloatingTooltip from "../core/FloatingTooltip.vue";
import ShareScreenIcon from "@/components/svg/ShareScreenIcon.vue";
import Modal from "@/components/core/Modal.vue";
import Notes from "@/components/meeting/Notes.vue";
import XIcon from "@/components/svg/XIcon.vue";
import { mapState } from "vuex";
import UserOptionsDropdown from "../user/UserOptionsDropdown.vue";
import AnalyticsService from "@/services/AnalyticsService";
const analyticsService = new AnalyticsService();
export default {
  data() {
    return {
      isMobile:
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ),
      showFinalizeModal: false,
      userModal: false,
      showGoogleLogin: false,
      archiveConfirm: false,
      endNotesObj: {
        text: "",
        error: "",
        loading: false,
      },
      recordSettings: {
        deviceSelected: "default",
        inputAudiodevice: [],
        error: "",
        getStreamError: false,
      },
      chevronRotate: false,
      clickLoading: false,
      floatingMessage: "",
      transcriptLoadingTimeout: null,
      docxExportLoading: false,
      showRequestAccessModal: false,
      accessModalCallback: () => {},
    };
  },
  watch: {
    endNotes(v) {
      this.endNotesObj.text = v;
    },
    archived(v) {
      if (v && !this.showFinalizeModal) {
        this.showFinalizeModal = true;
      }
    },
    "$route.query.export": {
      handler(v) {
        if (v) {
          const newQuery = { ...(this.$route.query || {}) };
          delete newQuery.export;
          if (v && this.archived) {
            this.showFinalizeModal = true;
          }
          this.$router.replace({ query: newQuery });
        }
      },
      immediate: true,
    },
  },
  filters: {
    formatAsTimecode(v) {
      function transform(secs) {
        var hours = Math.floor(secs / (60 * 60));
        var divisor_for_minutes = secs % (60 * 60);
        var minutes = Math.floor(divisor_for_minutes / 60);
        var divisor_for_seconds = divisor_for_minutes % 60;
        var seconds = Math.ceil(divisor_for_seconds);
        var obj = {
          h: hours,
          m: minutes,
          s: seconds,
        };
        return `
          ${obj.m < 10 ? "0" : ""}${obj.m}:${obj.s < 10 ? "0" : ""}${obj.s}
        `;
      }
      if (!v) return "00:00";
      return transform(v);
    },
  },
  components: {
    Button,
    MicIcon,
    DownChevron,
    ShareScreenIcon,
    Modal,
    Notes,
    XIcon,
    UserOptionsDropdown,
    FloatingTooltip,
  },
  methods: {
    onFinalizeModalConfirm({ modal }) {
      if (this.archived) {
        this.$router.push("/new");
      } else if (modal) {
        this.archiveMeeting();
      } else {
        this.toggleArchivingModal();
      }
    },
    async showUserModal(showModal) {
      this.userModal = showModal;
    },
    async upload() {
      if (this.sendMessageLoading) return;
      if (this.isRecording) {
        this.$store.dispatch("recording/stopRecording");
        this.$store.commit("meeting/uploading", true);
      }
    },
    async startRecording() {
      if (!this.isRecording) {
        this.chevronRotate = false;
        this.clickLoading = true;
        this.transcriptLoadingTimeout = setTimeout(() => {
          this.floatingMessage = "Getting live transcript ready...";
        }, 500);
        this.$emit("scrollToMessagesBottom");
        await this.$store
          .dispatch("transcription/getAccessToken")
          .then(() => {
            if (this.transcriptLoadingTimeout) clearTimeout(this.transcriptLoadingTimeout);
            this.transcriptLoadingTimeout = null;
            this.floatingMessage = "";
            return this.$store.dispatch("transcription/startTranscription", {
              meetingId: this.meetingId,
              meetingTitle: this.title,
            });
          })
          .then(() => this.$store.dispatch("recording/startAudioRecording"))
          .catch((err) => {
            console.error("recording/startAudioRecording", err);
            this.recordSettings.error = err.code;
            this.recordSettings.getStreamError = true;
          })
          .finally(() => {
            this.$emit("scrollToMessagesBottom");
            this.clickLoading = false;
            if (this.transcriptLoadingTimeout) clearTimeout(this.transcriptLoadingTimeout);
            this.transcriptLoadingTimeout = null;
          });
      }
    },
    changeChevronRotate(value) {
      this.chevronRotate = value;
    },
    async onSharedScreenButtonClick() {
      this.chevronRotate = false;
      this.clickLoading = true;
      this.transcriptLoadingTimeout = setTimeout(() => {
        this.floatingMessage = "Getting live transcript ready...";
      }, 500);
      this.$emit("scrollToMessagesBottom");
      await this.$store
        .dispatch("transcription/getAccessToken")
        .then(() => {
          if (this.transcriptLoadingTimeout) clearTimeout(this.transcriptLoadingTimeout);
          this.transcriptLoadingTimeout = null;
          this.floatingMessage = "";
          return this.$store.dispatch("transcription/startTranscription", {
            meetingId: this.meetingId,
            meetingTitle: this.title,
          });
        })
        .then(() => this.$store.dispatch("recording/startScreenRecording", {
          showRequestAccessModal: () => {
            this.showRequestAccessModal = true;
          },
          setAccessModalCallback: (callback) => {
            this.accessModalCallback = () => {
              callback();
              this.showRequestAccessModal = false;
            };
          },
        }))
        .catch((err) => {
          this.recordSettings.error = err.code;
          this.recordSettings.getStreamError = true;
        })
        .finally(() => {
          this.$emit("scrollToMessagesBottom");
          this.clickLoading = false;
          if (this.transcriptLoadingTimeout) clearTimeout(this.transcriptLoadingTimeout);
          this.transcriptLoadingTimeout = null;
        });
    },
    groupBy(list, keyGetter) {
      const map = new Map();
      list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });
      return map;
    },
    onRecordAudio(e) {
      this.$emit("onRecordAudio", e);
    },
    onDiscardCurrentMessage(e) {
      e.stopPropagation();
      this.$store.dispatch("recording/discardRecording");
    },
    selectDefaultDevice(deviceId) {
      this.$store.dispatch("recording/selectDefaultDevice", deviceId);
    },
    archiveMeeting() {
      //window.open("https://research.typeform.com/to/cw8vWEL3","_blank");
      this.endNotesObj.loading = true;
      this.$store
        .dispatch("meeting/setMeetingEndNotes", {
          meetingId: this.meetingId,
          endNotes: this.endNotesObj.text,
        })
        .then(() => {
          analyticsService.recordAnalyticsEvent("ADD_SUMMARY", {});
          return this.$store.dispatch("meeting/archiveMeeting", {
            id: this.meetingId,
          });
        })
        .catch((err) => {
          this.endNotesObj.error =
            err.message || "Could not complete your request.";
        })
        .finally(() => {
          this.endNotesObj.loading = false;
        });
    },
    clearArchiveModal() {
      // might be best to not clear in case of a misclick
    },
    toggleArchivingModal() {
      this.showFinalizeModal = !this.showFinalizeModal;
    },
    googleDocsExport() {
      if (this.user.googleDocsToken) {
        this.openGoogleSignin();
      } else {
        this.showGoogleLogin = true;
      }
    },
    openGoogleSignin() {
      this.showGoogleLogin = false;
      this.$store.dispatch("meeting/googleDocsExport", {
        id: this.meetingId,
      });
    },
    notionExport() {
      this.$store.dispatch("meeting/notionExport", {
        id: this.meetingId,
      });
    },
    docxExport() {
      this.docxExportLoading = true;
      this.$store.dispatch("meeting/docxExport", {
        id: this.meetingId,
      }).catch((err) => {
        console.error("docxExport", err);
      }).finally(() => {
        this.docxExportLoading = false;
      });
    },
  },
  computed: {
    ...mapState("user", {
      user: "user",
      userLoading: "loading",
    }),
    ...mapState("meeting", ["uploading"]),
    ...mapState("recording", ["devices", "defaultDeviceId", "isRecording"]),
    notRecording() {
      return !this.isMobile || !this.isRecording;
    },
  },
  props: {
    meetingId: {
      type: String,
      default: "",
    },
    endNotes: {
      type: String,
      default: "",
    },
    isPreviewing: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
    archived: {
      type: Boolean,
      default: false,
    },
    sendMessageLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.small-portal.vue-portal-target canvas {
  width: 40px;
  height: 40px;
}

.right-divider {
  border-right: 1px solid darkgray;
  padding-right: 8px;
}
#userOptionsFooterButton:hover ~ .userOptionsDropdown {
  display: block;
}
.summayModal .space-x-3 > :not([hidden]) ~ :not([hidden]) {
  margin: auto;
  justify-content: center;
  margin-bottom: 0.5rem;
}
.summayModal .extraFooter {
  flex-direction: row;
}
.summaryNotes .markdownInput {
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: absolute;
  width: 100%;
  overflow-x: hidden;
  height: 100%;
  min-height: 500px;
}
.rotate {
  -webkit-transition: -webkit-transform 0.1s ease-in-out;
  -ms-transition: -ms-transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
}
.rotated {
  transform: rotate(180deg); /* Equal to rotateZ(45deg) */
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
@media (max-width: 768px) {
  .summayModal .space-x-3 > :not([hidden]) ~ :not([hidden]) {
    width: 100%;
  }
  .wfull-m {
    width: 100%;
  }
  .summayModal .extraFooter {
    flex-direction: column;
  }
}
.notes::-webkit-scrollbar {
  display: none;
}
.summayModal .notes > .pl-3 {
  padding-left: 0px;
}

.bg-gradient-pro {
  background: -webkit-linear-gradient(45deg, #95a5f0, #f893a0 90%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.error-title {
  font-size: 28px;
  text-align: center;
  color: #000;
}
</style>
