<template>
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
        <path d="M19.077 12c.523 0 .923.383.923.882 0 3.441-3.108 6.324-7.077 6.706v2.647h1.724c.522 0 .923.383.923.883s-.4.882-.923.882H9.384c-.522 0-.923-.383-.923-.882 0-.5.4-.883.923-.883h1.693v-2.647C7.108 19.206 4 16.323 4 12.882c0-.5.4-.882.923-.882s.923.383.923.882c0 2.764 2.77 5 6.154 5 3.385 0 6.154-2.236 6.154-5 0-.5.4-.882.923-.882zM12 0c2.75 0 5 2.11 5 4.69v7.62c0 2.58-2.25 4.69-5 4.69s-5-2.11-5-4.69V4.69C7 2.11 9.25 0 12 0zm0 1.759c-1.719 0-3.125 1.319-3.125 2.93v7.621c0 1.612 1.406 2.931 3.125 2.931s3.125-1.319 3.125-2.93V4.69c0-1.612-1.406-2.931-3.125-2.931z" :fill="fill" fill-rule="nonzero"/>
        <circle cx="2.5" cy="2.5" r="2.5" transform="translate(13 15)" fill="#FF1E1E"/>
    </g>
</svg>
</template>
<script>
    export default {
        name: "MicIcon",
        props: {
            fill: {
                type: String,
                required: false,
                default: "#000",
        },
        }
    }
</script>
