import { env } from "process";
export default class LocalStorageService {
  private static readonly PREFIX = "yacx.";

  public save(payload: { [key: string]: any }): void {
    Object.keys(payload).forEach((key) => {
      if (key === "token") {
        this.setCookie("token", payload[key], payload[key] ? "MAX" : -100);
      } else {
        localStorage.setItem(
          `${LocalStorageService.PREFIX}${key}`,
          JSON.stringify(payload[key])
        );
      }
    });
  }

  public load(...keys: string[]): { [key: string]: any } {
    const result: { [key: string]: any } = {};
    keys.forEach((key) => {
      if (key === "token") {
        result[key] = this.getCookie("token");
      } else {
        try {
          result[key] = JSON.parse(
            localStorage.getItem(`${LocalStorageService.PREFIX}${key}`) ||
              "null"
          );
        } catch (error) {
          result[key] = null;
          console.error(`Could not parse ${key} from localStorage.`, error);
        }
      }
    });
    return result;
  }

  public saveBase64(payload: { [key: string]: any }): void {
    Object.keys(payload).forEach((key) => {
      if(payload[key]){
        localStorage.setItem(
          `${LocalStorageService.PREFIX}${key}`,
          Buffer.from(encodeURIComponent(JSON.stringify(payload[key]))).toString(
            "base64"
          )
        );
      }else{
        localStorage.removeItem(`${LocalStorageService.PREFIX}${key}`);
      }
    });
  }

  public loadBase64(...keys: string[]): { [key: string]: any } {
    const result: { [key: string]: any } = {};
    keys.forEach((key) => {
      try {
        result[key] = JSON.parse(
          decodeURIComponent(
            Buffer.from(
              localStorage.getItem(`${LocalStorageService.PREFIX}${key}`) ||
                // null
                "bnVsbA==",
              "base64"
            ).toString("utf8")
          )
        );
        if (key === "user" && result && !result[key].email) {
          result[key] = null
        }
      } catch (error) {
        result[key] = null;
        console.error(`Could not parse ${key} from localStorage.`, error);
      }
    });
    return result;
  }

  private setCookie(
    name: string,
    value: string,
    expirationDays?: number | "MAX"
  ) {
    const d = new Date();
    if (expirationDays === "MAX") {
      d.setTime(2147483647 * 1000);
    } else if (expirationDays) {
      d.setTime(d.getTime() + expirationDays * 24 * 60 * 60 * 1000);
    }
    const expires = "expires=" + d.toUTCString();
    document.cookie = `${name}=${value};${expirationDays ? expires : ""}${
      (env as any) === "production" ? "domain=.yac.com;" : ""
    }path=/`;
  }

  private getCookie(name: string) {
    const title = name + "=";
    for (let c of document.cookie.split(";")) {
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(title) == 0) {
        return c.substring(title.length, c.length);
      }
    }
    return "";
  }
}
