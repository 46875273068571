










import Spinner from "@/components/svg/Spinner.vue";
import Vue from "vue";
export default Vue.extend({
  components: { Spinner },
  name: "UploadingFile",
})
