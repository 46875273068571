import { UnresolvedVideo } from "@/models/Video";
import store from "@/store";
import { BaseServiceError } from "./BaseServiceError";

export type TranscriptionData = {
  transcript: string;
  dialogues: {
    startSeconds: number;
    transcript: string;
    isFinal: boolean;
  }[];
  words: {
    startSeconds: number;
    word: string;
    isFinal: boolean;
  }[];
};

export default class LiveTranscriptionService {
  public static async getAccessToken() {
    try {
      const response = await fetch(
        process.env.VUE_APP_SYMBL_ACCESS_TOKEN as string,
        {
          // mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      return data.accessToken as string;
    } catch (error) {
      console.error("LiveTranscriptionService.getAccessToken");
      return null;
      // throw new LiveTranscriptionServiceError(
      //   "COULD_NOT_GET_ACCESS_TOKEN",
      //   error
      // );
    }
  }

  ws: WebSocket | null;
  currentStartSeconds = 0;
  transcript: TranscriptionData["transcript"] = "";
  nonFinalTranscript: string = "";
  dialogues: TranscriptionData["dialogues"] = [];
  words: TranscriptionData["words"] = [];
  onDataUpdate: (data: TranscriptionData) => void;
  constructor(params: {
    meetingId: string;
    meetingTitle: string;
    onDataUpdate: (data: TranscriptionData) => void;
    accessToken: string;
  }) {
    const { meetingId, meetingTitle, onDataUpdate, accessToken } = params;

    // console.log("LiveTranscriptionService.constructor.meetingId", meetingId);

    this.onDataUpdate = onDataUpdate;

    if (!accessToken) {
      this.ws = null;
      return;
    }

    const languageCode = store.state.user.user
      ? store.state.user.user.languageCode || "en-US"
      : "en-US";

    // const userEmail = store.state.user.user
    //   ? store.state.user.user.email || "unidentified@yac.com"
    //   : "unidentified@yac.com";

    const userName = store.state.user.user
      ? store.state.user.user.realName ||
        store.state.user.user.displayName ||
        "Unidentified"
      : "Unidentified";

    const symblEndpoint = `wss://api.symbl.ai/v1/realtime/insights/${meetingId}?access_token=${accessToken}`;

    this.ws = new WebSocket(symblEndpoint);

    this.ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      this.currentStartSeconds = Number(store.state.recording.duration);
      if (data.type === "message" && data.message.punctuated !== undefined) {
        // console.log("onmessage", data.message);
        const transcript: string = data.message.punctuated.transcript || "";
        const lastDialoge = this.dialogues[this.dialogues.length - 1];
        if (!data.message.isFinal) {
          this.nonFinalTranscript = transcript;
          this.transcript = this.transcript.concat(" " + transcript).trim();
          if (lastDialoge && !lastDialoge.isFinal) {
            lastDialoge.transcript = transcript;
          } else {
            this.dialogues.push({
              startSeconds: this.currentStartSeconds,
              transcript: transcript,
              isFinal: false,
            });
          }
        } else {
          this.transcript = this.transcript
            .replace(this.nonFinalTranscript, "")
            .concat(" " + transcript)
            .trim();
          this.nonFinalTranscript = "";
          if (lastDialoge && !lastDialoge.isFinal) {
            lastDialoge.transcript = transcript;
            lastDialoge.isFinal = true;
          } else if (lastDialoge.startSeconds === this.currentStartSeconds) {
            lastDialoge.transcript = lastDialoge.transcript
              .concat(" " + transcript)
              .trim();
          } else {
            this.dialogues.push({
              startSeconds: this.currentStartSeconds,
              transcript: transcript,
              isFinal: true,
            });
          }
        }
        this.onDataUpdate(this.transcriptionData);
        console.log(transcript);
      }
    };

    this.ws.onerror = (err) => {
      console.error("LiveTranscriptionService.ws.onerror", err);
    };

    this.ws.onclose = () => {
      this.transcript = "";
      this.dialogues = [];
      this.words = [];
    };

    // Fired when the connection succeeds.
    this.ws.onopen = () => {
      if (this.ws) {
        this.ws.send(
          JSON.stringify({
            type: "start_request",
            meetingTitle: meetingTitle || "Untitled Meeting",
            insightTypes: [],
            config: {
              confidenceThreshold: 0.25,
              languageCode,
              speechRecognition: {
                encoding: "LINEAR16",
                sampleRateHertz: 44100,
              },
            },
            speaker: {
              // userId: userEmail,
              name: userName,
            },
          })
        );
      }
    };
  }

  public getTranscriptSections(transcript: string): string[] {
    const sections = [];
    const separators = [".", "?", "!"];
    let current = "";
    for (const char of transcript) {
      current += char;
      if (separators.includes(char)) {
        if (current) sections.push(current);
        current = "";
      }
    }
    const finalSections: string[][] = [];
    let currentSelection: string[] = [];
    for (const section of sections) {
      if (section.length < 60) {
        finalSections.push(currentSelection);
        finalSections.push([section]);
        currentSelection = [];
        continue;
      } else if (
        currentSelection.length < 4 ||
        currentSelection.join(" ").length < 120
      ) {
        finalSections.push(currentSelection);
        currentSelection = [];
      } else {
        currentSelection.push(section);
      }
    }
    return finalSections.map((x) => x.join(" ")).filter(Boolean);
  }

  public get transcriptionData(): TranscriptionData {
    return {
      transcript: this.transcript,
      dialogues: this.dialogues,
      words: this.words,
    };
  }

  public sendStream(stream: MediaStream) {
    // console.log("sendStream");
    const AudioContext = window.AudioContext;
    const context = new AudioContext();
    const source = context.createMediaStreamSource(stream);
    const processor = context.createScriptProcessor(1024, 1, 1);
    const gainNode = context.createGain();

    source.connect(gainNode);
    gainNode.connect(processor);
    processor.connect(context.destination);

    const transcriptServiceContext = this;

    processor.onaudioprocess = function (e) {
      // convert to 16-bit payload
      const inputData =
        e.inputBuffer.getChannelData(0) || new Float32Array(this.bufferSize);
      const targetBuffer = new Int16Array(inputData.length);
      for (let index = inputData.length; index > 0; index--) {
        targetBuffer[index] = 32767 * Math.min(1, inputData[index]);
      }
      // Send audio stream to websocket.
      if (transcriptServiceContext.ws && transcriptServiceContext.ws.readyState === WebSocket.OPEN) {
        // console.log("sendStream.send");
        transcriptServiceContext.ws.send(targetBuffer.buffer);
      }
    };
  }

  public stop() {
    this.transcript = "";
    this.dialogues = [];
    this.words = [];
    this.onDataUpdate(this.transcriptionData);
    if (this.ws) {
      this.ws.send(
        JSON.stringify({
          type: "stop_request",
        })
      );
      this.ws.close();
    }
  }
}

export type LiveTranscriptionServiceErrorCode = "COULD_NOT_GET_ACCESS_TOKEN";
export class LiveTranscriptionServiceError extends BaseServiceError<LiveTranscriptionServiceErrorCode> {
  mapErrorCodeToMessage(code: "COULD_NOT_GET_ACCESS_TOKEN"): string {
    switch (code) {
      case "COULD_NOT_GET_ACCESS_TOKEN":
        return "Could not get access token";
      default:
        return "Unknown error";
    }
  }
}
