import Vue from 'vue'
import Vuex, { Store } from "vuex";
import { createStore, Module } from "vuex-smart-module";
import AuthModule, { AuthState } from "./modules/AuthModule";
import MeetingModule, { MeetingState } from "./modules/MeetingModule";
import RecordingModule, { RecordingState } from './modules/RecordingModule';
import TranscriptionModule, {
  TranscriptionState,
} from "./modules/TranscriptionModule";
import UserModule, { UserState } from "./modules/UserModule";
import { MicrophonePermission } from "./plugins/PermissionsPlugin";

Vue.use(Vuex);

const store = createStore(
  new Module({
    modules: {
      user: UserModule,
      auth: AuthModule,
      meeting: MeetingModule,
      recording: RecordingModule,
      transcription: TranscriptionModule,
    },
  }),
  {
    plugins: [MicrophonePermission()],
  }
) as Store<YacXStore>;

export interface YacXStore {
  user: UserState;
  auth: AuthState;
  meeting: MeetingState;
  recording: RecordingState;
  transcription: TranscriptionState;
}

export default store;