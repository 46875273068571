<template>
  <button
    @click="$emit('click', $event)"
    @submit="$emit('click', $event)"
    @mousedown="$emit('mousedown', $event)"
    @mouseup="$emit('mouseup', $event)"
    @mouseover="$emit('mouseover', $event)"
    @blur="$emit('blur', $event)"
    :disabled="disabled"
    :class="`
      relative
      text-sm
      my-auto
      font-medium
      bg-white
      rounded-full
      py-2
      px-4
      focus:outline-none focus:ring
      ${loading ? 'cursor-wait btn-loading' : 'cursor-pointer'}
    `"
  >
    <div class="button-child flex items-center">
      <slot />
    </div>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  }
};
</script>

<style>
.btn-loading::after {
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: currentColor;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
  opacity: 0.88 !important;
}
.btn-loading .button-child {
  visibility: hidden;
  opacity: 0;
}
@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}
</style>