export default class UniqueNameService {
    static colors = [
        "Amaranth",
        "Amber",
        "Amethyst",
        "Apricot",
        "Aqua",
        "Aquamarine",
        "Azure",
        "Beige",
        "Black",
        "Blue",
        "Blush",
        "Bronze",
        "Brown",
        "Chocolate",
        "Coffee",
        "Copper",
        "Coral",
        "Crimson",
        "Cyan",
        "Emerald",
        "Fuchsia",
        "Gold",
        "Gray",
        "Green",
        "Harlequin",
        "Indigo",
        "Ivory",
        "Jade",
        "Lavender",
        "Lime",
        "Magenta",
        "Maroon",
        "Moccasin",
        "Olive",
        "Orange",
        "Peach",
        "Pink",
        "Plum",
        "Purple",
        "Red",
        "Rose",
        "Salmon",
        "Sapphire",
        "Scarlet",
        "Silver",
        "Tan",
        "Teal",
        "Tomato",
        "Turquoise",
        "Violet",
        "Yellow",
    ];

    static animals = [
        "Bear",
        "Bird",
        "Cat",
        "Chicken",
        "Cow",
        "Crocodile",
        "Dog",
        "Elephant",
        "Ferret",
        "Fox",
        "Hamster",
        "Killer Whale",
        "Kitty",
        "Lion",
        "Monkey",
        "Otter",
        "Panda",
        "Penguin",
        "Pig",
        "Puppy",
        "Rabbit",
        "Raccoon",
        "Red Panda",
        "Seal",
        "Shark",
        "Squid",
        "Tiger",
        "Toucan",
        "Turtle",
        "Whale"
    ]

    static generate() {
        const color = this.colors[Math.floor(Math.random() * this.colors.length)];
        const animal = this.animals[Math.floor(Math.random() * this.animals.length)];

        return `${color} ${animal}`;
    }

    static icon(name: string) {
        const [color, animal] = name.split(" ");
        return `${animal.toLowerCase()}.png`;
    }
}