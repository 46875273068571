























































import Vue from 'vue';
import { mapState } from "vuex";
import UserLoginModal from '../user/UserLoginModal.vue';
export default Vue.extend({
  components: {
    UserLoginModal
  },
  data() {
    return {
      message: {
        title: "",
        status: "",
        by: "",
        photoURL: "",
      },
      showModal: false,
    }
  },
  props:{
    archived:{
      type:Boolean,
      default: false
    },
    photoURL:{
      type:String,
      default: ''
    },
    meetingId:{
      type:String,
      required: true,
      default: ''
    },
  },
  methods: {
    handleClick() {
      if (!this.archived) this.$emit("startRecording");
    }
  },
  computed: {
    ...mapState("user", {
      user: "user",
      userLoading: "loading",
    }),
  },
})
