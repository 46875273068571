



















































import Vue from 'vue'
import FormItem from './FormItem.vue'
export default Vue.extend({
  components: { FormItem },
  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({
        displayName: '',
        email: ''
      }),
    },
    plan: {
      type: String,
      required: true,
    },
  },
  watch: {
    value: {
      handler(newValue) {
        const value = Object.assign(this.value, newValue);
        this.$emit('input', value);
      },
      deep: true,
    },
  },
})
