<template>
  <transition name="fade">
    <div
      v-if="show"
      class="
        fixed
        top-0
        left-0
        inset-0
        w-screen
        h-screen
        flex
        items-center
        justify-center
        bg-smoke
        z-[200]
        select-none
      "
      style="flex-direction: column;"
      @click.self="closeIfShown"
    >
      <div
        class="
            relative
            max-h-screen
            w-full
            p-6
            max-w-2xl
            shadow-lg
            rounded-lg
            flex flex-col
            space-y-5
            m-4
            md:m-0
            modal-content
          "
          style="overflow: scroll; align-items: center;">
        <div
          class="
            relative
            max-h-screen
            w-full
            p-6
            max-w-2xl
            bg-white
            shadow-lg
            rounded-lg
            flex flex-col
            space-y-5
            m-4
            md:m-0
            modal-content
          "
        >
        <div>
          <button
            v-if="showClose"
            aria-label="close"
            class="absolute top-0 right-0 text-xl text-gray-300 my-2 mx-4"
            @click.prevent="close"
          >
            ×
          </button>
          <slot v-if="slotMode" />
          <div v-else class="flex flex-col -3">
            <h1 class="text-lg font-medium text-gray-900">{{ title }}</h1>
            <p class="text-md text-gray-900">{{ body }}</p>
          </div>
        </div>
          <div 
            class="flex justify-end space-x-3 extraFooter"
          >
            <slot name="extraFooter" />
            <Button
              v-if="!hideConfirm"
              @click.prevent="confirm"
              :loading="confirmLoading"
              type="button"
              :class="`
                inline-flex
                justify-center
                w-auto
                px-4
                py-2
                leading-6
                border-2
                ${
                  confirmType === 'danger'
                    ? `text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red border-red-600`
                    : `text-black bg-yellow-300 hover:opacity-75 focus:outline-none focus:border-yellow-400 focus:shadow-outline-yellow border-yellow-300`
                }
              `"
            >
              {{ confirmText }}
            </Button>
            <!-- <Button
              class="
                inline-flex
                w-auto
                cursor-pointer
                border-2 
                px-4
                py-2
                bg-white
                text-sm
                leading-6
                font-medium
                text-gray-700
                hover:text-gray-500
                focus:outline-none
                focus:border-yellow-300
                focus:shadow-outline-yellow
              "
              v-if="showClose"
              @click.prevent="close"
            >
              {{ closeText }}
            </Button> -->
          </div>
        </div>
        <div
          class="
            relative
            max-h-screen
            w-full
            p-6
            max-w-2xl
            bg-white
            shadow-lg
            rounded-lg
            flex flex-col
            space-y-5
            m-4
            md:m-0
            modal-content
          "
        >
          <div>
            <slot name="sideContent" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Button from "./Button.vue"

export default {
  components: {
    Button
  },
  props: {
    confirmText: {
      required: false,
      type: String,
      default: "Permanently Delete",
    },
    confirmType: {
      required: false,
      type: String,
      default: "danger",
      validator: function (value) {
        // The value must match one of these strings
        return ['success', 'danger'].indexOf(value) !== -1
      }
    },
    confirmLoading: {
      required: false,
      type: Boolean,
      default: false,
    },
    closeText: {
      required: false,
      type: String,
      default: "Back",
    },
    title: {
      required: false,
      type: String,
      default: "Delete User",
    },
    body: {
      required: false,
      type: String,
      default: "Are you sure you'd like to delete this user?",
    },
    show: {
      required: true,
      type: Boolean,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    closeOnConfirm: {
      type: Boolean,
      default: true,
    },
    lockKeyboard: {
      type: Boolean,
      default: true,
    },
    backgroundClose: {
      type: Boolean,
      default: true,
    },
    slotMode: {
      type: Boolean,
      default: false,
    },
    hideConfirm: {
      type: Boolean,
      default: false,
    },
    hideButtons: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    show(value) {
      if (value) {
        if (this.showClose) {
          document.addEventListener("keydown", this.onKeyDown);
        }
        return document.querySelector("body").classList.add("overflow-hidden");
      }

      if (this.showClose) {
        document.removeEventListener("keydown", this.onKeyDown);
      }
      return document.querySelector("body").classList.remove("overflow-hidden");
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    confirm() {
      this.$emit("confirm");
      if (this.closeOnConfirm) {
        this.close();
      }
    },
    closeIfShown() {
      if (this.showClose && this.backgroundClose) {
        this.close();
      }
    },
  },
  created() {
    const component = this;
    this.onKeyDown = function(e) {
      if (e.key === "Escape") {
        component.close();
      } else {
        component.$emit('keydown', e);
        if (this.lockKeyboard) {
          e.preventDefault();
        }
      }
    }
  },
  beforeDestroy() {
    // cleanup if modal is destroyed via unexpected browser navigation
    if (this.show) {
      try {
        document.removeEventListener("keydown", this.onKeyDown);
        document.querySelector("body").classList.remove("overflow-hidden");
      } catch (e) {
        console.error(e);
      }
    }
  },
};
</script>

<style scoped>

.fade-enter-active,
.fade-leave-active {
  transition: all 240ms ease;
}

.fade-enter,
.fade-leave-to {
  transform: scale(1.02);
  opacity: 0;
}
::-webkit-scrollbar {
    display: none;
}
</style>