import FirebaseService from "./FirebaseService";
import { BaseServiceError } from "./BaseServiceError";

export default class AuthService extends FirebaseService {
  async signInAnonymously() {
    try {
      await this.auth.signInAnonymously();
    } catch (error) {
      console.error("AuthService.signInAnonymously", error);
      throw new AuthServiceError("COULD_NOT_SIGN_IN_ANONYMOUSLY", error);
    }
  }

  async signInWithToken(token:string){
    try {
      await this.auth.signInWithCustomToken(token);
    } catch (error) {
      console.error("AuthService.signInWithCustomToken", error);
      throw new AuthServiceError("COULD_NOT_SIGN_IN_WITH_TOKEN", error);
    }
  }

  async logout() {
    try {
      await this.auth.signOut();
    } catch (error) {
      console.error("AuthService.signOut", error);
      throw new AuthServiceError("COULD_NOT_SIGN_OUT", error);
    }
  }
}

export type AuthServiceErrorCode =
  | "COULD_NOT_SIGN_IN_WITH_TOKEN"
  | "COULD_NOT_SIGN_IN_WITH_GOOGLE"
  | "COULD_NOT_SIGN_IN_ANONYMOUSLY"
  | "COULD_NOT_SIGN_OUT";

export class AuthServiceError extends BaseServiceError<AuthServiceErrorCode> {
  mapErrorCodeToMessage(Code: AuthServiceErrorCode): string {
    switch (Code) {
      case "COULD_NOT_SIGN_IN_WITH_TOKEN":
        return "Could not sign in with token.";
      case "COULD_NOT_SIGN_IN_WITH_GOOGLE":
        return "Could not sign in with Google.";
      case "COULD_NOT_SIGN_IN_ANONYMOUSLY":
        return "Could not sign in anonymously.";
      case "COULD_NOT_SIGN_OUT":
        return "Could not sign out.";
      default:
        return "There has been an unknown error.";
    }
  }
}
