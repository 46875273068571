<template>
  <Message :message="message" hideEdit :meetingId="meetingId" liveMode>
    <div class="absolute z-0 bottom-0 left-0 right-0 top-0" >
      <video
        ref="video"
        controls
        autoplay
        muted
        class="object-contain absolute z-50 w-full h-full"
      >
        <source :srcObject="srcObject" />
      </video>
      <div
        class="
          w-full absolute
          flex items-center justify-center
          top-0 bottom-0
        "
      >
        <portal-target name="waveform2" />
      </div>
    </div>
  </Message>
 </template>

<script>
import { mapState } from 'vuex';
import Message from '../message/Message.vue';
export default {
  components: { Message, },
  name: "LivePlayer",
  props: {
    srcObject: null,
    recordedData: null,
    user: null,
    meetingId: {
      type: String,
      default: "",
    },
  },
  methods: {
    onTitleChange() {
      this.$emit('onTitleChange', this.title);
    },
  },
  data() {
    return {
      title: "",
      transcriptionDataCopy: {
        transcript: "",
        dialogues: [],
        words: [],
      },
    };
  },
  computed: {
    ...mapState({
      transcriptionData: (state) => state.transcription.transcriptionData,
    }),
    message() {
      return {
        status: "",
        title: this.title,
        by: this.user,
        sentAt: {
          toDate() {
            return new Date();
          },
        },
        ...this.transcriptionDataCopy,
      }
    }
  },
  watch: {
    recordedData: function (n) {
      this.$refs.video.pause();
      this.$refs.video.muted = false;
      this.$refs.video.controls = true;
      this.$refs.video.srcObject = null;
      this.$refs.video.src = window.URL.createObjectURL(n);
      this.$refs.video.play();
    },
    transcriptionData: {
      handler(v) {
        this.transcriptionDataCopy = v;
      },
      deep: true
    },
  },
  mounted() {
    this.$refs.video.srcObject = this.srcObject;
    this.$refs.video.controls = false;
  },
};
</script>
