<template>
<div class="flex gap-0">
    <svg width="30" height="22" viewBox="0 0 30 22" xmlns="http://www.w3.org/2000/svg">
        <g class="hgray" fill-rule="evenodd">
            <g  transform="translate(0 1)" stroke="#141414">
                <path d="M7.638 9.59c1.97 0 3.754.808 5.045 2.112a7.23 7.23 0 0 1 2.092 5.103c0 .352-.15.66-.398.931-.324.353-.805.645-1.386.888-1.408.588-3.382.876-5.353.876-1.972 0-3.946-.288-5.353-.876-.581-.243-1.062-.535-1.386-.888-.25-.271-.399-.58-.399-.931 0-1.993.8-3.797 2.092-5.103a7.078 7.078 0 0 1 5.046-2.111z"/>
                <path d="M16.75 7.938a5.7 5.7 0 0 1 4.064 1.7A5.825 5.825 0 0 1 22.5 13.75c0 .318-.185.578-.46.802-.405.33-1 .584-1.697.777-1.606.447-3.74.561-5.575.371-.14-.64-.48-1.965-1.08-2.895-.473-.732-1.362-1.524-1.868-1.945a6.676 6.676 0 0 1 2.038-2.024c.868-.551 1.867-.897 2.893-.897z"/>
                <ellipse cx="7.638" cy="4.545" rx="4.274" ry="4.045"/>
                <ellipse cx="16.751" cy="3.719" rx="3.406" ry="3.219"/>
            </g>
        </g>
    </svg>
    <div class="rounded-full overflow-hidden p-1 min-w-[1.5rem] bg-gray-200 text-xs font-medium text-center">
        {{ amount }}
    </div>
</div>
</template>
<script>
    export default {
        name: "PeopleIcon",
        props: {
            amount: {
                type: String,
                required: false,
                default: '0',
        },
        }
    }
</script>
<style scoped>
.hgray {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    fill: white
}
.hgray:hover {
 fill: #d6d6d6
}
</style>
