<template>
   <section id="meetingHeader" class="top-0 fixed flex flex-col justify-between w-full h-auto py-2 px-3 bg-white z-40">
    <div class="flex justify-between w-full h-auto py-2 px-3">
    <article class="flex sm:flex-row flex-col justify-start w-full overflow-hidden">
      <div
        class="
          w-20
          flex-shrink-0
          flex
          mb-2
          sm:my-auto
          sm:flex-col
          justify-center
          px-1
        "
      >
        <a href="/new" target="_blank" class="logo-link">
          <Logo />
        </a>
      </div>
      <div class="w-full text-lg my-auto truncate sm:ml-4">
        <input 
          type="text"
          ref="titleTopInput"
          v-model="titleCopy"
          @input="archived ? () => {} : updateTitle"
          @keydown.enter="updateMeetingTitle"
          :class="`
            bg-transparent
            truncate
            max-w-full
            text-left
            border-b-2
            border-solid
            border-transparent
            ${isMobile ? 'tw-space-disable' : ''}
            ${archived ? '' : 'hover:border-yellow-300'}
            transition-all
          `"
        />
      </div>
    </article>
    <article class="flex justify-start w-auto">
      <FloatingTooltip
        class="flex-col justify-center hidden sm:flex"
        position="bottom"
        tooltipClasses="w-96 max-h-[600px] overflow-y-scroll"
      >
        <template v-slot:button>
          <div class="flex flex-col justify-center">
            <PeopleIcon :amount="`${participantUsers.length}`" />
          </div>
        </template>
        <div class="p-3 pb-0 flex justify-center">
          <Button
            @click="newParticipants.modalShow = true"
            :class="`
              relative flex-shrink-0 
              my-auto 
              focus:outline-yellow-300
              bg-transparent transition-colors
              rounded-[20px]
                `"
            style="
              width: 90%;
              border-radius: 20px;
              border: solid 1px rgba(20, 20, 20, 0.1);
              background-color: #fff;
            "
          >
            <p class="block w-full py-1" style="font-weight: bold">
              Invite Participant
            </p>
          </Button>
        </div>
        <Participants :participants="participants" />
      </FloatingTooltip>
      <Button class="ml-2 border-2" @click="newParticipants.modalShow = true">
        Share
      </Button>
    </article>
    <ModalDual
      :show="newParticipants.modalShow"
      slotMode
      confirmText="Invite"
      confirmType="success"
      :hideButtons="true"
      :confirmLoading="newParticipants.loading"
      @confirm="addNewParticipants"
      :closeOnConfirm="false"
      @close="closeNewParticipantsModal"
      class="sharedModal"
    >
      <div class="w3-bar w3-black">
        <button
          :class="`${
            newParticipants.inviteShow ? 'font-active' : ''
          } tabs-action w3-bar-item w3-button mr-5 py-3`"
          @click="gotoTab('invite')"
        >
          Invite
        </button>
      </div>
      <div
        style="max-height: 430px; overflow-y: auto"
        class="inviteModal"
      >
        <div class="w3-bar w3-black pt-5">
          <div
            id="invite"
            :class="`tabs-content ${
              newParticipants.inviteShow ? 'd-block' : 'd-none'
            }`"
          >
            <div>
              <p style="font-weight: bold">Invite People</p>
              <span style="font-size: 16px; color: #7b7b7b">
                Add participants to this meeting via email
              </span>
            </div>
            <div class="flex items-center mt-3">
              <div
                class="flex items-center inviteBox px-5 mr-1"
                style="flex-grow: 1"
              >
                <p class="grow-0" style="font-weight: bold" v-if="!isMobile">
                  Email
                </p>
                <FormItem
                  hideLabel
                  label="New Participants"
                  class="grow formInvite"
                  style="flex-grow: 1"
                >
                  <TagInput
                    emailValidation
                    placeholder="jane@doe.com"
                    :list="newParticipants.emails"
                    @keyup="checkEmailsFormEmpty"
                    @change="checkEmailsFormEmpty"
                    @submit="addNewParticipants"
                  />
                </FormItem>
              </div>
             
            </div>
            <p class="text-red-600 p-2">{{ newParticipants.error }}</p>
            <Participants :participants="participants" />
          </div>
        </div>
      </div>
      <template v-slot:sideContent >
        <div class="w3-bar w3-black">
          <div class="flex">
            <div class="flex flex-col flex-grow">
              <p style="font-weight: bold">Invite Link</p>
              <span style="font-size: 16px; color: #7b7b7b">
                Copy this link for easy instant invites
              </span>
            </div>
            <Button
              @click="copyLink"
              type="button"
              :class="`
                font-bold
                inline-flex
                justify-center
                w-auto
                px-4
                py-2
                mr-1
                leading-6
                border-2
                text-black hover:opacity-75 focus:outline-none`"
            >
              Copy
            </Button>
          </div>
          <div v-if="failedToCopyInviteLink" class="flex flex-col mt-3 w-full">
            <div
              class="flex items-center inviteBox px-5 mr-1 w-full"
              style="flex-grow: 1"
            >
              <p class="grow-0 py-2">{{ meetingLink }}</p>
            </div>
            <p class="pt-2 text-red-500">
              We could not copy the link to your clipboard, please copy the link above.
            </p>
          </div>
        </div>
        <p v-if="copiedLinkToClipboard" class="pt-2 text-green-600">
          Link copied to clipboard.
        </p>
      </template>
    </ModalDual>
    <Modal
      :show="settings.modalShow"
      slotMode
      confirmText="Save"
      confirmType="success"
      :confirmLoading="settings.loading"
      @confirm="saveMeetingSettings"
      :closeOnConfirm="false"
      @close="closeSettingsModal"
    >
      <PageHeaderText>
        <template v-slot:title>Settings</template>
      </PageHeaderText>
      <FormItem
        label="Meeting Title"
        v-model="settings.title"
        placeholder="Super Important Meeting"
        @submit="saveMeetingSettings"
      />
      <p class="text-red-600 pb-2">{{ settings.error }}</p>
    </Modal>
    </div>
    <div class="block lg:hidden flex justify-between w-full h-auto py-2 px-3">
    <article class="flex justify-start w-auto space-x-4">
      <div class="w3-bar w3-black flex-col">
        <button 
          v-for="(tab, i) in headerTabs" 
          :key="`tab-${i}`"
          :class="`${tab.hidden ? 'hidden' : ''} ${tab.status ? 'font-active' : ''} tabs-action w3-bar-item w3-button mr-5 py-3`"
          @click="$emit('changeTab', tab.name)"
        >
          {{tab.name}}
        </button>
      </div>
    </article>
    </div>
  </section>
</template>

<script>
import Button from "@/components/core/Button.vue";
import Logo from "@/components/svg/Logo.vue";
import Modal from "../core/Modal.vue";
import ModalDual from "../core/ModalDual.vue";
import FormItem from "../form/FormItem.vue";
import TagInput from "../form/TagInput.vue";
import PageHeaderText from "../typography/PageHeaderText.vue";
import PeopleIcon from "../svg/PeopleIcon.vue";
import { mapState } from "vuex";
import FloatingTooltip from "../core/FloatingTooltip.vue";
import Participants from "@/components/meeting/Participants.vue";
import { copyToClipboard, debounce, validateEmail } from "@/util";
import AnalyticsService from '@/services/AnalyticsService';
const analyticsService = new AnalyticsService();
export default {
  components: {
    Button,
    Logo,
    Modal,
    ModalDual,
    FormItem,
    TagInput,
    PageHeaderText,
    PeopleIcon,
    FloatingTooltip,
    Participants,
  },
  data() {
    return {
      isMobile:
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ),
      meetingLink: `${window.location.host}${window.location.pathname}`,
      newParticipants: {
        modalShow: false,
        inviteShow: true,
        exportShow: false,
        error: "",
        emails: [],
        failures: [],
        successes: [],
        loading: false,
      },
      failedToCopyInviteLink: false,
      copiedLinkToClipboard: false,
      format: "docs",
      options: [
        {
          title: "Export to Google Docs",
          value: "docs",
          cardImage: "https://codeclimate.com/github/sagalbot/linktoimage.png",
        },
        {
          title: "Noticon",
          value: "noticon",
          cardImage: "https://codeclimate.com/github/sagalbot/linktoimage.png",
        },
      ],
      settings: {
        modalShow: false,
        error: "",
        loading: false,
        title: "",
      },
      titleCopy: "",
    };
  },
  computed: {
    ...mapState("user", {
      user: "user",
      userLoading: "loading",
    }),
    participantUsers() {
      return this.participants;
    },
    orderedParticipantsLogged() {
      if (this.user) {
        const withoutUser = this.participantUsers.filter(
          (u) => u.id !== this.user.id
        );
        return [{ ...this.user, current: true }, ...withoutUser].filter((u) =>
          Boolean(u.email)
        );
      }
      return this.participantUsers.filter((u) => Boolean(u.email));
    },
    orderedParticipantsAnonymous() {
      return this.participantUsers.filter((u) => !u.email);
    },
  },
  methods: {
    updateTitle(e) {
      this.titleCopy = e.target.value;
    },
    copyLink() {
      try {
        copyToClipboard(`${window.location.href}`);
        this.copiedLinkToClipboard = true;
        analyticsService.recordAnalyticsEvent("INVITE_LINK_COPY", {
          meetingId: this.meetingId,
        });
      } catch (error) {
        this.failedToCopyInviteLink = true;
      }
    },
    gotoTab(tab) {
      if (tab == "invite") {
        this.newParticipants.inviteShow = true;
        this.newParticipants.exportShow = false;
      } else {
        this.newParticipants.inviteShow = false;
        this.newParticipants.exportShow = true;
      }
    },
    saveMeetingSettings() {
      this.settings.loading = true;
      this.$store
        .dispatch("meeting/updateMeeting", {
          id: this.meetingId,
          name: this.settings.title,
        })
        .then(() => {
          this.settings.modalShow = false;
        })
        .catch((err) => {
          this.settings.error =
            err.message || "Could not update meeting settings.";
        })
        .finally(() => {
          this.settings.loading = false;
        });
    },
    updateMeetingTitle() {
      if (this.title === this.titleCopy) return;
      if (this.titleCopy === "") {
        this.titleCopy = this.title;
        return;
      }
      this.$store
        .dispatch("meeting/updateMeeting", {
          id: this.meetingId,
          name: this.titleCopy,
        })
        .then(() => {
          analyticsService.recordAnalyticsEvent("TITLE_EDIT", {});
        })
    },
    checkEmailsFormEmpty(e) {
       if (
         this.newParticipants.emails.length || 
         validateEmail(e.target.value.toLowerCase().trim())
       ) {
        this.newParticipants.error = "";
      }
    },
    addNewParticipants() {
      this.newParticipants.successes = [];
      this.newParticipants.failures = [];
      this.newParticipants.loading = true;
      if (!this.newParticipants.emails.length) {
        this.newParticipants.error = "Please enter at least one email.";
        this.newParticipants.loading = false;
        return;
      }
      Promise.allSettled(
        this.newParticipants.emails.map((email) => {
          return this.$store
            .dispatch("meeting/addMeetingParticipant", {
              email,
              meetingId: this.meetingId,
            })
            .then(() => {
              this.newParticipants.successes.push(email);
            })
            .catch(() => {
              this.newParticipants.failures.push(email);
            });
        })
      )
        .then(() => {
          this.newParticipants.emails = [];
        })
        .catch(() => {
          const qty = this.newParticipants.successes.length
            ? `${this.newParticipants.failures.length} `
            : "";
          this.newParticipants.error = `Could not add ${qty}${
            qty === "1" ? "participant" : "participants"
          }.`;
          this.newParticipants.emails = [...this.newParticipants.failures];
        })
        .finally(() => {
          this.newParticipants.loading = false;
        });
    },
    closeNewParticipantsModal() {
      this.newParticipants.modalShow = false;
      this.newParticipants.emails = [];
      this.newParticipants.failures = [];
      this.newParticipants.successes = [];
      this.newParticipants.error = "";
      this.copiedLinkToClipboard = false;
      this.failedToCopyInviteLink = false;
    },
    closeSettingsModal() {
      this.settings.modalShow = false;
      this.settings.title = this.title;
      this.settings.error = "";
    },
  },
  watch: {
    title(v) {
      this.settings.title = v;
      this.titleCopy = v;
      this.$refs.titleTopInput.style.width = `${(v.length)*0.65 + 0.85}rem`;
    },
    titleCopy(v) {
      this.$refs.titleTopInput.style.width = `${(v.length)*0.65 + 0.85}rem`;
      const updateDebounced = debounce(
        "UPDATE_MEETING_TITLE",
        () => this.updateMeetingTitle(),
        2500
      );
      updateDebounced();
    },
  },
  mounted() {
    this.settings.title = this.title;
  },
  props: {
    participants: null,
    headerTabs: [],
    title: {
      type: String,
      default: "",
    },
    meetingId: {
      type: String,
      required: true,
    },
    archived: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.participant-box {
  background-color: #fff;
}
.participant-box:hover {
  background-color: #ededed;
}
.participant-box-anonymous:last-child {
  border-radius: 0 0 15px 15px;
}
.email-wrapper {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

#participantsToggle:hover #participantsListDropdown {
  display: block;
}

#participantsListDropdown {
  top: 20px;
  right: -20px;
}

.d-block {
  display: block;
}
.d-none {
  display: none;
}
.tabs-action {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition-duration: 200ms;
  border-bottom: solid 2px white;
  font-weight: normal;
}
.formInvite {
  padding: 0px !important;
}
.formInvite > .border {
  border-width: 0px;
}
.formInvite > div {
  padding: 2px;
}

.inviteBox {
  border-radius: 20px;
  border: solid 1px rgba(20, 20, 20, 0.1);
}
.font-active {
  border-bottom: solid 2px #f3c93c;
  font-weight: bold
}

/* Hide scrollbar for Chrome, Safari and Opera */
.inviteModal::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.inviteModal {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.b-155 {
  bottom: -155px;
}
.b-145 {
  bottom: -145px;
}
.tw-space-disable {
  --tw-space-x-reverse: 1!important;
}
.logo-link{
  width:100%;
}
</style>
