











import Vue from 'vue'
export default Vue.extend({
  data() {
    return {
      linkLabels: [
        'About',
        'Privacy',
        'Contact',
        'Careers',
      ],
    }
  },
})
